import axios from 'axios';
import authHeader from './auth.header';
import {handleRequestError} from '../errorHandler';
import axiosCache from '../components/cache/axiosCache'

const SDM_SERVICE_URL = '/api/sdm';
const ctype = {'Content-Type': 'application/json'}

class CompanyService {
    async getCompanies() {
        try {
            const response = await axiosCache.get(`${SDM_SERVICE_URL}/company`,
                {
                    headers: {...authHeader(), ...ctype}, id: 'company',
                    params: {isActive: false}
                });
            return response.data;
        } catch (error) {
            console.log('COMPANY FETCH ERROR ', error)
        }
    }

    async updateCompany(companyData: {
        name: string;
        shortName: string;
        address1: string;
        address2: string;
        city: string;
        region: string;
        code: string;
        countryCode: string;
        roles: { id: string; name: string; }[];
        active: boolean;
    }) {
        try {
            const response = await axios.post(`${SDM_SERVICE_URL}/updateCompany`, companyData,
                {
                    headers: {...authHeader(), ...ctype}
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

    async createCompany(
        companyData: {
            name: string;
            shortName: string;
            address1: string;
            address2: string;
            city: string;
            region: string;
            code: string;
            countryCode: string;
            roles: { id: string; name: string; }[];
            active: boolean;
        }) {
        try {
            const response = await axios.post(`${SDM_SERVICE_URL}/company`, companyData,
                {
                    headers: {...authHeader(), ...ctype}
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }


    async deleteCompany(companyShortName: string) {
        try {
            const response = await axios.delete(`${SDM_SERVICE_URL}/company`,
                {
                    params: {
                        shortName: companyShortName
                    },
                    headers: {...authHeader(), ...ctype}
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }
}

export default new CompanyService();
