import React, {useEffect, useState} from 'react';
import {
    Box, Button, CssBaseline, TextField, Typography, Dialog,
    DialogContent, Switch, FormControlLabel, Select, MenuItem, Grid
} from '@mui/material';
import UserService from '../../services/user.service';
import CompanyService from '../../services/company.service';
import RoleService from '../../services/role.service';
import {Link, useNavigate} from "react-router-dom";
import {dropdownItems} from "../../views/dropdownItems";
import VOCSETLayout from '../../views/VOCSETLayout';
import {Company, Role} from "../../types/APITypes";

function CreateUser() {
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [ownerShortName, setOwnerShortName] = useState('');
    const [currentUserOwnerName, setCurrentUserOwnerName] = useState('');
    const [currentUserOwnerShortName, setCurrentUserOwnerShortName] = useState('');
    const [roleList, setRoleList] = useState<{ id: string; name: string; }[]>([]);
    const [roles, setRoles] = useState('');
    const [userCompanyRole, setUserCompanyRole] = useState<Role[]>([]);
    const [active, setActive] = useState(true);
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [ownerName, setOwnerName] = useState('');
    const navigate = useNavigate();

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/UserDashboard')
    };

    useEffect(() => {
        const userProfileObject = JSON.parse(localStorage.getItem('userProfile') || '{}');

        if (userProfileObject?.ownerName && userProfileObject?.ownerShortName) {
            setCurrentUserOwnerShortName(userProfileObject.ownerShortName)
            setCurrentUserOwnerName(userProfileObject.ownerName);
        } else {
            console.error('ownerName not found in userProfile');
        }
    }, []);

    useEffect(() => {
        if (!currentUserOwnerName) return;

        async function fetchCompanies() {
            try {
                const response = await CompanyService.getCompanies();
                const fetchedCompanies: Company[] = response.result;
                if (fetchedCompanies && fetchedCompanies.length > 0) {
                    setCompanies(fetchedCompanies);

                    const matchingCompany = fetchedCompanies.find(
                        company => company.name === currentUserOwnerName
                    );

                    if (matchingCompany) {
                        setUserCompanyRole(matchingCompany.roles);
                    } else {
                        setError('No matching company found for the current user.');
                    }

                } else {
                    setError('No companies available.');
                }
            } catch (error) {
                console.error('Error fetching companies:', error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching companies. Please try again.');
            }
        }

        fetchCompanies();
    }, [currentUserOwnerName]);

    useEffect(() => {
        async function fetchRoles() {
            try {
                const response = await RoleService.getRolesByType('user');
                const fetchedRoles = response.result;
                if (fetchedRoles && fetchedRoles.length > 0) {
                    setRoleList(fetchedRoles);
                } else {
                    setError('No roles available.');
                }
            } catch (error) {
                console.error('Error fetching roles:', error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching roles. Please try again.');
            }
        }

        fetchRoles();
    }, []);

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        try {
            const selectedRoleObject = roleList.find((role) => role.name === roles);

            if (!selectedRoleObject) {
                throw new Error('Selected role not found.');
            }

            const userData = {
                username,
                firstName,
                lastName,
                roles: [{id: selectedRoleObject.id, name: selectedRoleObject.name}],
                active,
                ownerShortName
            };

            await UserService.createUser(userData);
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            console.error('Error creating user:', error);
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error creating user. Please try again.');
        }
    };

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{alignSelf: 'flex-start'}}>
                        Create User
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    fullWidth
                                    id="roles"
                                    label="Roles"
                                    value={roles}
                                    renderValue={(selected) => (selected === '' ? 'Select Role' : selected)}
                                    onChange={(e) => setRoles(e.target.value)}
                                    sx={{marginTop: '16px'}}
                                >
                                    <MenuItem disabled value="">
                                        {roles ? '' : 'Select Role'}
                                    </MenuItem>
                                    {roleList && roleList.map((role: any) => (
                                        <MenuItem key={role.id} value={role.name}>{role.name}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    fullWidth
                                    id="company"
                                    label="Company"
                                    value={userCompanyRole.some(role => role.id === 'ROLE_INTERNAL') ? ownerShortName : currentUserOwnerName}
                                    disabled={!userCompanyRole.some(role => role.id === 'ROLE_INTERNAL')}
                                    renderValue={(selected) => {
                                        if (!userCompanyRole.some(role => role.id === 'ROLE_INTERNAL')) {
                                            setOwnerShortName(currentUserOwnerShortName);
                                            return currentUserOwnerName;
                                        } else {
                                            const selectedCompany = companies.find(company => company.shortName === selected);
                                            return selectedCompany ? selectedCompany.name : 'Select Company';
                                        }
                                    }}
                                    onChange={(e) => setOwnerShortName(e.target.value)}
                                >
                                    {!userCompanyRole.some(role => role.id === 'ROLE_INTERNAL') ? (
                                        <MenuItem value={currentUserOwnerName} disabled>
                                            {currentUserOwnerName}
                                        </MenuItem>
                                    ) : (
                                        companies.map((company: Company) => (
                                            <MenuItem key={company.id} value={company.shortName}>
                                                {company.name}
                                            </MenuItem>
                                        ))
                                    )}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={active}
                                        onChange={(e) => setActive(e.target.checked)}
                                        color="primary"
                                        inputProps={{'aria-label': 'toggle checkbox'}}
                                    />
                                }
                                label="Active"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{width: 'auto', mb: 2}}
                            >
                                Save
                            </Button>
                            <Button
                                component={Link}
                                to="/UserDashboard"
                                variant="contained"
                                color="primary"
                                sx={{width: 'auto', mb: 2, marginLeft: 3}}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Box>
                    {error && (
                        <Typography variant="body2" color="error" sx={{ alignSelf: 'flex-start' }}>
                            {error}
                        </Typography>
                    )}
                </Box>
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">User created successfully!</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default CreateUser;
