import React, {useState, useEffect} from 'react';
import {
    Box,
    Button,
    CssBaseline,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Switch, Select, MenuItem
} from '@mui/material';
import CompanyService from '../../services/company.service';
import RoleService from '../../services/role.service';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {dropdownItems} from '../../views/dropdownItems';
import VOCSETLayout from "../../views/VOCSETLayout";
import {Grid} from '@mui/material';
import UserService from "../../services/user.service";


interface EditCompanyProps {
    onSuccess: () => void;
}

interface Role {
    id: string;
    name: string;
}

const EditCompany: React.FC<EditCompanyProps> = ({onSuccess}) => {
    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [region, setRegion] = useState('');
    const [code, setCode] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [roleList, setRoleList] = useState<Role[]>([]);
    const [roles, setRoles] = useState<Role | null>(null);
    const [active, setActive] = useState(true);
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const location = useLocation();
    const [action, setAction] = useState<'save' | 'delete' | null>(null);
    const navigate = useNavigate();
    const company = location.state || {}

    useEffect(() => {
        setName(company.name || '');
        setShortName(company.shortName || '');
        setAddress1(company.address1 || '');
        setAddress2(company.address2 || '');
        setCity(company.city || '');
        setRegion(company.region || '');
        setCode(company.code || '');
        setCountryCode(company.countryCode || '');
        setRoles(company.roles[0] || null);
        setActive(company.hasOwnProperty('active') ? company.active : true);
    }, [company]);


    useEffect(() => {
        async function fetchRoles() {
            try {
                const response = await RoleService.getRolesByType('company');
                const fetchedRoles = response.result;
                if (fetchedRoles && fetchedRoles.length > 0) {
                    setRoleList(fetchedRoles);
                } else {
                    setError('No roles available.');
                }
            } catch (error) {
                console.error('Error fetching roles:', error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching roles. Please try again.');
            }
        }
        fetchRoles();
    }, []);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/CompanyDashboard')
    };

    const handleSave = async (actionType?: 'activate' | 'save') => {
        try {

            if (!roles) {
                throw new Error('No role selected.');
            }

            const updatedCompanyData = {
                name,
                shortName,
                address1,
                address2,
                city,
                region,
                code,
                countryCode,
                roles: [{ id: roles.id, name: roles.name }],
                active: actionType === 'activate' ? true : active
            };
            await CompanyService.updateCompany(updatedCompanyData);
            onSuccess();
            setAction('save');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            console.error('Error updating company:', error);
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error updating company. Please try again.');
        }
    };

    const handleDelete = async () => {
        try {
            await CompanyService.deleteCompany(shortName);
            onSuccess();
            setAction('delete');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            console.error('Error deleting company:', error);
            setError('Error deleting company. Please try again.');
        }
    };

    let dialogContent = '';
    if (action === 'save') {
        dialogContent = 'Update complete';
    } else if (action === 'delete') {
        dialogContent = 'Deactivation complete';
    }

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{ alignSelf: 'flex-start' }}>
                        Edit Company
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2} sx={{ paddingBottom: 4 }}>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="shortName"
                                    label="Short Name"
                                    value={shortName}
                                    onChange={(e) => setShortName(e.target.value)}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="address1"
                                    label="Address Line 1"
                                    value={address1}
                                    onChange={(e) => setAddress1(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="address2"
                                    label="Address Line 2"
                                    value={address2}
                                    onChange={(e) => setAddress2(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="city"
                                    label="City"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="region"
                                    label="Region"
                                    value={region}
                                    onChange={(e) => setRegion(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="code"
                                    label="Code"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="countryCode"
                                    label="Country Code"
                                    value={countryCode}
                                    onChange={(e) => setCountryCode(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    fullWidth
                                    id="roles"
                                    label="Roles"
                                    value={roles ? roles.name : ''}
                                    onChange={(e) => {
                                        const selectedRoleName = e.target.value;
                                        const selectedRole = roleList.find(role => role.name === selectedRoleName);
                                        if (selectedRole) {
                                            setRoles(selectedRole);
                                        }
                                    }}
                                >
                                    {roleList && roleList.map((role: any) => (
                                        <MenuItem key={role.id} value={role.name}>{role.name}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => handleSave('save')}
                                variant="contained"
                                sx={{ width: 'auto', mb: 2 }}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={() => {
                                    if (active) {
                                        handleDelete()
                                    } else {
                                        handleSave('activate');
                                    }
                                }}
                                variant="contained"
                                color={active ? "secondary" : "primary"}
                                sx={{
                                    width: 'auto',
                                    mb: 2,
                                    marginLeft: 3,
                                    bgcolor: active ? "red" : "green",
                                    '&:hover': {
                                        bgcolor: active ? "red" : "green"
                                    }
                                }}
                            >
                                {active ? "Deactivate" : "Activate"}
                            </Button>
                            <Button
                                component={Link}
                                to="/CompanyDashboard"
                                variant="contained"
                                color="primary"
                                sx={{width: 'auto', mb: 2, marginLeft: 3}}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Box>
                    {error && (
                        <Typography variant="body2" color="error" sx={{ alignSelf: 'flex-start' }}>
                            {error}
                        </Typography>
                    )}
                </Box>
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">{dialogContent}</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default EditCompany;
