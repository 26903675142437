import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    CssBaseline,
    TextField,
    Typography,
    Dialog,
    DialogContent,
    Grid,
    Select,
    MenuItem,
    SelectChangeEvent
} from '@mui/material';

import UserService from '../../services/user.service';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { dropdownItems } from '../../views/dropdownItems';
import VOCSETLayout from "../../views/VOCSETLayout";
import CompanyService from "../../services/company.service";
import RoleService from '../../services/role.service';
import { Role } from "../../types/APITypes";

interface EditUserProps {
    onSuccess: () => void;
}

const EditUser: React.FC<EditUserProps> = ({ onSuccess }) => {
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [roleList, setRoleList] = useState<Role[]>([]);
    const [roles, setRoles] = useState<Role | null>(null);
    const [active, setActive] = useState(true);
    const [companies, setCompanies] = useState<any[]>([]);
    const [ownerName, setOwnerName] = useState('');
    const [ownerShortName, setOwnerShortName] = useState('');
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const location = useLocation();
    const [action, setAction] = useState<'save' | 'delete' | null>(null);
    const navigate = useNavigate();
    const user = location.state || {}
    const [disableCompanySelect, setDisableCompanySelect] = useState(false);

    useEffect(() => {
        setUsername(user.username || '');
        setFirstName(user.firstName || '');
        setLastName(user.lastName || '');
        setRoles(user.roles[0] || null);
        setActive(user.hasOwnProperty('active') ? user.active : true);
        setOwnerShortName(user.ownerShortName || '');

        if (user.ownerName) {
            setDisableCompanySelect(true);
        }

    }, [user]);

    useEffect(() => {
        async function fetchCompanies() {
            try {
                const response = await CompanyService.getCompanies();
                const fetchedCompanies = response.result;
                if (fetchedCompanies && fetchedCompanies.length > 0) {
                    setCompanies(fetchedCompanies);
                    const ownerCompany = fetchedCompanies.find((company: any) => company.shortName === user.ownerShortName);
                    if (ownerCompany) {
                        setOwnerName(ownerCompany.name);
                        setOwnerShortName(ownerCompany.shortName);
                    }
                } else {
                    setError('No companies available.');
                }
            } catch (error) {
                console.error('Error fetching companies:', error);
                setError('Error fetching companies. Please try again.');
            }
        }
        fetchCompanies();
    }, [user.ownerName]);

    useEffect(() => {
        async function fetchRoles() {
            try {
                const response = await RoleService.getRolesByType('user');
                const fetchedRoles = response.result;
                if (fetchedRoles && fetchedRoles.length > 0) {
                    setRoleList(fetchedRoles);
                } else {
                    setError('No roles available.');
                }
            } catch (error) {
                console.error('Error fetching roles:', error);
                setError('Error fetching roles. Please try again.');
            }
        }
        fetchRoles();
    }, []);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/UserDashboard')
    };

    const handleSave = async (actionType?: 'activate' | 'save') => {
        try {
            if (!roles) {
                throw new Error('No role selected.');
            }

            const updatedUserData = {
                username,
                firstName,
                lastName,
                ownerShortName,
                roles: [{ id: roles.id, name: roles.name }],
                active: actionType === 'activate' ? true : active,
            };
            
            await UserService.updateUser(updatedUserData);
            onSuccess();
            setAction('save');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            console.error('Error updating user:', error);
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error updating user. Please try again.');
        }
    };

    const handleDelete = async () => {
        try {
            await UserService.deleteUser(username);
            onSuccess();
            setAction('delete');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            console.error('Error deactivating user:', error);
            setError('Error deactivating user. Please try again.');
        }
    };

    const handleCompanyChange = (event: SelectChangeEvent<string>) => {
        const companyName = event.target.value;
        const selectedCompany = companies.find(company => company.name === companyName);
        if (selectedCompany) {
            setOwnerName(selectedCompany.name);
            setOwnerShortName(selectedCompany.shortName);
        }
    };

    let dialogContent = '';
    if (action === 'save') {
        dialogContent = 'Update complete';
    } else if (action === 'delete') {
        dialogContent = 'Deactivation complete';
    }

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{ alignSelf: 'flex-start' }}>
                        Edit User
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2} sx={{ paddingBottom: 4 }}>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    fullWidth
                                    id="roles"
                                    label="Roles"
                                    value={roles ? roles.name : ''}
                                    onChange={(e) => {
                                        const selectedRoleName = e.target.value;
                                        const selectedRole = roleList.find(role => role.name === selectedRoleName);
                                        if (selectedRole) {
                                            setRoles(selectedRole);
                                        }
                                    }}
                                    sx={{ marginTop: '16px' }}
                                >
                                    {roleList && roleList.map((role: Role) => (
                                        <MenuItem key={role.id} value={role.name}>{role.name}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    fullWidth
                                    id="companies"
                                    label="Companies"
                                    value={ownerName}
                                    onChange={handleCompanyChange}
                                    sx={{ marginTop: '16px' }}
                                    disabled={disableCompanySelect}
                                >
                                    {companies && companies.map((company: any) => (
                                        <MenuItem key={company.id} value={company.name}>{company.name}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => handleSave('save')}
                                variant="contained"
                                sx={{ width: 'auto', mb: 2 }}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={() => {
                                    if (active) {
                                        handleDelete()
                                    } else {
                                        handleSave('activate');
                                    }
                                }}
                                variant="contained"
                                color={active ? "secondary" : "primary"}
                                sx={{
                                    width: 'auto',
                                    mb: 2,
                                    marginLeft: 3,
                                    bgcolor: active ? "red" : "green",
                                    '&:hover': {
                                        bgcolor: active ? "red" : "green"
                                    }
                                }}
                            >
                                {active ? "Deactivate" : "Activate"}
                            </Button>
                            <Button
                                component={Link}
                                to="/UserDashboard"
                                variant="contained"
                                color="primary"
                                sx={{ width: 'auto', mb: 2, marginLeft: 3 }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Box>
                    {error && (
                        <Typography variant="body2" color="error" sx={{ alignSelf: 'flex-start' }}>
                            {error}
                        </Typography>
                    )}
                </Box>
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">{dialogContent}</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default EditUser;