import React, {useState, useEffect} from 'react';
import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import UserService from '../../services/user.service';
import Table from '@mui/material/Table';
import {Link} from 'react-router-dom';
import Button from "@mui/material/Button";
import VOCSETLayout from '../../views/VOCSETLayout';
import {dropdownItems} from '../../views/dropdownItems';
import {MoonLoader} from "react-spinners";
import {User} from "../../types/APITypes";

createTheme();
const UserDashboard = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [error, setError] = useState<string>('');
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(true);
    const [privileges, setPrivileges] = useState<string[]>([]);

    const resize = () => {
        setOpen(window.innerWidth >= 760);
    };
    const handleDrawerToggle = () => {
        setOpen(!open);
    };
    useEffect(() => {
        // @ts-ignore
        window.addEventListener('resize', resize.bind(this));
        resize();
    }, []);
    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                )
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                )
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        })
    }, [])

    useEffect(() => {

        const storedPrivileges = localStorage.getItem('privileges');
        if (storedPrivileges) {
            setPrivileges(storedPrivileges.split(','));
        }
        const fetchUsers = async () => {
            try {
                const response = await UserService.getUsers();
                if (response) {
                    setLoading(false)
                }
                const fetchedUsers = response.result;
                if (fetchedUsers && fetchedUsers.length > 0) {
                    setUsers(fetchedUsers);
                } else {
                    setError('No users available.');
                }
            } catch (error) {
                // @ts-ignore
                console.error('Error fetching users:', error.response ? error.response.data : error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching users. Please try again.');
            }
        };

        fetchUsers();
    }, []);

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                {loading ? (
                    <MoonLoader color="#282c34"/>
                ) : (
                    users.length === 0 ? (
                        <p>No users found.</p>
                    ) : (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Username</TableCell>
                                        <TableCell>First Name</TableCell>
                                        <TableCell>Last Name</TableCell>
                                        <TableCell>Roles</TableCell>
                                        <TableCell>Company</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((user) => (
                                        <TableRow key={user.id} style={{backgroundColor: user.active ? '' : '#f5f5f5'}}>
                                            <TableCell>{user.username}</TableCell>
                                            <TableCell>{user.firstName}</TableCell>
                                            <TableCell>{user.lastName}</TableCell>
                                            <TableCell>{user.roles.length > 0 ? user.roles[0].name : ""}</TableCell>
                                            <TableCell>{user.ownerName}</TableCell>
                                            <TableCell>
                                                <Link to={`/EditUser`} state={user} className="clt-row"
                                                      style={{textDecoration: 'none', color: 'inherit'}}>
                                                    <Button variant="outlined">Edit</Button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                )}
            </VOCSETLayout>
        </div>
    );
}

export default UserDashboard;


