import React, { Component } from 'react';
import Main from './views/Main';

class App extends Component {
    componentDidMount() {
        // Check if this is the first run of the app, set flag for handling caches below
        const isFirstRun = sessionStorage.getItem('isFirstRun');

        if (!isFirstRun) {
            localStorage.clear();
            sessionStorage.setItem('isFirstRun', 'true');
        }
    }

    render() {
        return (
            <div>
                <Main />
            </div>
        );
    }
}

export default App;
