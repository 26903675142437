import React, {useEffect, useState} from 'react';
import {
    Box, Button, CssBaseline, TextField, Typography, Dialog,
    DialogContent, Select, MenuItem
} from '@mui/material';
import {Link, useNavigate} from "react-router-dom";
import {dropdownItems} from "../../views/dropdownItems";
import VOCSETLayout from '../../views/VOCSETLayout';
import {Grid} from '@mui/material';
import ExchangeService from "../../services/exchange.service";
import CompanyService from "../../services/company.service";


function CreateExchange() {
    const [name, setName] = useState('');
    const [mic, setMic] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [active, setActive] = useState(true);
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [companies, setCompanies] = useState<any[]>([]);
    const [companyName, setCompanyName] = useState('');
    const navigate = useNavigate();

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/ExchangeDashboard')
    };

    useEffect(() => {
        async function fetchCompanies() {
            try {
                const response = await CompanyService.getCompanies();
                const fetchedCompanies = response.result;
                if (fetchedCompanies && fetchedCompanies.length > 0) {
                    setCompanies(fetchedCompanies);
                } else {
                    setError('No companies available.');
                }
                setCompanies(fetchedCompanies);
            } catch (error) {
                console.error('Error fetching companies:', error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching companies. Please try again.');
            }
        }

        fetchCompanies();
    }, []);

    const handleSubmit = async (event: {
        preventDefault: () => void;
    }) => {
        event.preventDefault();

        try {
            const selectedCompany = companies.find(company => company.shortName === companyName);
            if (!selectedCompany) {
                setError('Selected company not found.');
                return;
            }

            const exchangeData = {
                name,
                mic,
                countryCode,
                active,
                ownerName: selectedCompany.shortName,
            };

            await ExchangeService.createExchange(exchangeData);
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            console.error('Error creating exchange:', error);
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error creating exchange. Please try again.');
        }
    };

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{alignSelf: 'flex-start'}}>
                        Create Exchange
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 3}}>
                        <Grid container spacing={2} sx={{paddingBottom: 4}}>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="mic"
                                    label="MIC"
                                    value={mic}
                                    onChange={(e) => setMic(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="countryCode"
                                    label="Country Code"
                                    value={countryCode}
                                    onChange={(e) => setCountryCode(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    fullWidth
                                    id="company"
                                    label="Company"
                                    value={companyName}
                                    renderValue={(selected) => (selected === '' ? 'Select Company' : selected)}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    sx={{marginTop: '16px'}}
                                >
                                    {companies.map((company: any) => (
                                        <MenuItem key={company.id} value={company.shortName}>{company.name}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{width: 'auto', mb: 2}}
                            >
                                Save
                            </Button>
                            <Button
                                component={Link}
                                to="/ExchangeDashboard"
                                variant="contained"
                                color="primary"
                                sx={{width: 'auto', mb: 2, marginLeft: 3}}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Box>
                    {error && (
                        <Typography variant="body2" color="error" sx={{ alignSelf: 'flex-start' }}>
                            {error}
                        </Typography>
                    )}
                </Box>
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">Exchange created successfully!</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default CreateExchange;
