import axios from 'axios';
import {handleRequestError} from '../errorHandler';
import authHeader from "./auth.header";
import axiosCache from '../components/cache/axiosCache'
import {Role} from "../types/APITypes";

const SDM_SERVICE_URL = '/api/sdm';
const ctype = {'Content-Type': 'application/json'}

class AccountService {
    async getAccounts() {
        try {
            const response = await axiosCache.get(`${SDM_SERVICE_URL}/account`,
                {
                    headers: {...authHeader(), ...ctype},
                    id: 'account'
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

    async getAccountOrAlias(name: string) {
        try {
            const response = await axios.get(`${SDM_SERVICE_URL}/account`,
                {
                    headers: {...authHeader(), ...ctype},
                    params: {account: name}
                });
            return response.data;
        } catch (error) {
            console.log(error)
            handleRequestError(error);
        }
    }

    async updateAccount(accountData: {
        id: string;
        name: string;
        owner: string;
        client: string;
        active: boolean;
    }) {
        try {
            const response = await axios.post(`${SDM_SERVICE_URL}/account/update`, accountData,
                {
                    headers: {...authHeader(), ...ctype}
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

    async createAccount(accountData: {
        name: string;
        owner: string;
        client: string;
        active: boolean;
        role: Role;
    }) {
        try {
            const response = await axios.post(`${SDM_SERVICE_URL}/account`, accountData,
                {
                    headers: {...authHeader(), ...ctype}
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }


    async deleteAccount(code: string) {
        try {
            const response = await axios.delete(`${SDM_SERVICE_URL}/account`,
                {
                    headers: {...authHeader(), ...ctype},
                    params: {code: code}
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }
}

export default new AccountService();
