import React, {useState, useEffect} from 'react';
import {
    Box,
    Button,
    CssBaseline,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    Grid
} from '@mui/material';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {dropdownItems} from '../../views/dropdownItems';
import VOCSETLayout from '../../views/VOCSETLayout';
import AliasService from '../../services/alias.service';

interface EditAliasProps {
    onSuccess: () => void;
}

const EditAlias: React.FC<EditAliasProps> = ({onSuccess}) => {
    const [id, setId] = useState('');
    const [type, setType] = useState('');
    const [fieldName, setFieldName] = useState('');
    const [entityName, setEntityName] = useState('');
    const [alias, setAlias] = useState('');
    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [action, setAction] = useState<'save' | 'delete' | null>(null);
    const [exchangeMic, setExchangeMic] = useState('');
    const [assetClass, setAssetClass] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const aliasData = location.state || {};
    const entityMap: Record<string, string> = {
        'Contract': 'contractCode',
        'Account': 'accountName',
        'Exchange': 'micCode',
        'Company': 'companyShortName'
    };


    useEffect(() => {
        setId(aliasData.id || '');
        setType(aliasData.type || '');
        setFieldName(aliasData.fieldName || '');
        setAlias(aliasData.alias || '');
        if (aliasData.type === 'Contract'){
            setExchangeMic(aliasData.exchangeMicCode || '');
            setAssetClass(aliasData.assetClass || '');
        }
        const prevAliasVal = entityMap[aliasData.type];
        setValue(aliasData[prevAliasVal] || '');
        setEntityName(entityMap[aliasData.type] || '')
    }, [aliasData]);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/AliasDashboard');
    };

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        
        try {
            const updatedAlias = {
                id,
                type,
                fieldName,
                alias,
                [entityName]: value,
                // @ts-ignore
                exchangeMicCode: type === 'Contract' ? exchangeMic : '',
                assetClass: type === 'Contract' ? assetClass : ''
            };
            
            await AliasService.updateAlias(updatedAlias);
            onSuccess();
            setAction('save');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            console.error('Error updating alias:', error);
            setError('Error updating alias. Please try again.');
        }
    };

    const handleDelete = async () => {
        try {
            await AliasService.deleteAlias(type, id);
            onSuccess();
            setAction('delete');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            console.error('Error deleting company:', error);
            setError('Error deleting company. Please try again.');
        }
    };

    let dialogContent = '';
    if (action === 'save') {
        dialogContent = 'Update complete';
    } else if (action === 'delete') {
        dialogContent = 'Deactivation complete';
    }


    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%'
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{alignSelf: 'flex-start'}}>
                        Edit Alias
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 3, width: '100%'}}>
                        <Grid container spacing={2} sx={{paddingBottom: 4}}>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    id="type"
                                    label="Entity"
                                    value={type}
                                    disabled
                                    sx={{marginTop: '16px'}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="value"
                                    label="Value"
                                    value={value}
                                    disabled
                                    onChange={(e) => setValue(e.target.value)}
                                    sx={{marginTop: '16px'}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="alias"
                                    label="Alias"
                                    value={alias}
                                    onChange={(e) => setAlias(e.target.value)}
                                    sx={{marginTop: '16px'}}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{width: 'auto', mb: 2}}
                                >
                                    Save
                                </Button>
                                <Button
                                    onClick={() => {
                                        handleDelete()
                                    }}
                                    variant="contained"
                                    color={"secondary"}
                                    sx={{
                                        width: 'auto',
                                        mb: 2,
                                        marginLeft: 3,
                                    }}
                                >
                                    Delete
                                </Button>
                                <Button
                                    component={Link}
                                    to="/AliasDashboard"
                                    variant="contained"
                                    color="primary"
                                    sx={{width: 'auto', mb: 2, marginLeft: 3}}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    {error && (
                        <Typography variant="body2" color="error" sx={{ alignSelf: 'flex-start' }}>
                            {error}
                        </Typography>
                    )}
                </Box>
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">{dialogContent}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default EditAlias;
