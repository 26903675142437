import React, { useState, useEffect } from 'react';
import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Tab,
    Button
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import AliasService from '../../services/alias.service';
import Table from '@mui/material/Table';
import { Link, useLocation } from 'react-router-dom';
import VOCSETLayout from '../../views/VOCSETLayout';
import { dropdownItems } from '../../views/dropdownItems';
import { MoonLoader } from "react-spinners";
import { Alias } from "../../types/APITypes";

createTheme();

const AliasDashboard = () => {
    const [aliases, setAliases] = useState<Alias[]>([]);
    const [error, setError] = useState<string>('');
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(true);
    const [selectedEntityName, setSelectedEntityName] = useState<string>('');
    const location = useLocation();
    const entityMap: Record<string, string> = {
        'Contract': 'contractCode',
        'Account': 'accountName',
        'Exchange': 'micCode',
        'Company': 'companyShortName'
    };
    
    const resize = () => {
        setOpen(window.innerWidth >= 760);
    };

    useEffect(() => {
        window.addEventListener('resize', resize);
        resize();
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);

    useEffect(() => {
        const fetchAliases = async () => {
            try {
                const response = await AliasService.findAliases();
                setLoading(false);
                const fetchedAliases = response.result;
                if (fetchedAliases && fetchedAliases.length > 0) {
                    const updatedAliases = fetchedAliases.map((alias: { [x: string]: any; type: string | number; }) => ({
                        ...alias,
                        value: alias[entityMap[alias.type]]
                    }));
                    setAliases(updatedAliases);
                    const passedEntityName = location.state?.selectedEntityName;
                    const entityNameToSet = passedEntityName && updatedAliases.some((alias: Alias) => alias.type === passedEntityName)
                        ? passedEntityName
                        : updatedAliases[0].type;
                    setSelectedEntityName(entityNameToSet);
                } else {
                    setError('No aliases available.');
                }
            } catch (error) {
                // @ts-ignore
                console.error('Error fetching aliases:', error.response ? error.response.data : error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching aliases. Please try again.');
            }
        };

        fetchAliases();
    }, [location.state]);

    const uniqueEntityNames = Array.from(new Set(aliases.map(alias => alias.type)));

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <Tabs
                    value={selectedEntityName}
                    onChange={(event, newValue) => setSelectedEntityName(newValue)}
                    aria-label="Alias Tabs"
                >
                    {uniqueEntityNames.map((entityName, index) => (
                        <Tab key={index} label={entityName} value={entityName} />
                    ))}
                </Tabs>
                {loading ? (
                    <MoonLoader color="#282c34" />
                ) : (
                    aliases.length === 0 ? (
                        <p>No aliases found.</p>
                    ) : (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Value</TableCell>
                                        <TableCell>Alias</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {aliases.filter(alias => alias.type === selectedEntityName).map(alias => (
                                        <TableRow key={alias.id}>
                                            <TableCell>{alias.value}</TableCell>
                                            <TableCell>{alias.alias}</TableCell>
                                            <TableCell>
                                                <Link to={`/EditAlias`} state={alias} className="clt-row" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                    <Button variant="outlined">Edit</Button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                )}
            </VOCSETLayout>
        </div>
    );
};

export default AliasDashboard;
