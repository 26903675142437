import React, { useState, useEffect } from 'react';
import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tab,
    Tabs
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import ExchangeService from '../../services/exchange.service';
import Table from '@mui/material/Table';
import { Link, useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";
import VOCSETLayout from '../../views/VOCSETLayout';
import { dropdownItems } from '../../views/dropdownItems';
import { MoonLoader } from "react-spinners";
import {Exchange} from "../../types/APITypes";


createTheme();
const ExchangeDashboard = () => {
    const [exchanges, setExchanges] = useState<Exchange[]>([]);
    const [error, setError] = useState<string>('');
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(true);
    const [selectedOwner, setSelectedOwner] = useState<string>('');
    const navigate = useNavigate();

    const resize = () => {
        setOpen(window.innerWidth >= 760);
    };
    useEffect(() => {
        // @ts-ignore
        window.addEventListener('resize', resize.bind(this));
        resize();
    }, []);
    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                )
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                )
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        })
    }, [])

    useEffect(() => {
        const fetchExchanges = async () => {
            try {
                const response = await ExchangeService.getExchanges();
                if (response) {
                    setLoading(false)
                }
                const fetchedExchanges = response.result;
                if (fetchedExchanges && fetchedExchanges.length > 0) {
                    setExchanges(fetchedExchanges);
                    setSelectedOwner(fetchedExchanges[0].ownerName)
                } else {
                    setError('No exchanges available.');
                }
            } catch (error) {
                // @ts-ignore
                console.error('Error fetching exchanges:', error.response ? error.response.data : error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching exchanges. Please try again.');
            }
        };

        fetchExchanges();
    }, []);

    const uniqueOwners = Array.from(new Set(exchanges.map(exchange => exchange.ownerName)));
    const filteredExchanges = exchanges.filter(exchange => exchange.ownerName === selectedOwner);

    const handleRowClick = (mic: string) => {
        navigate(`/ProductDashboard`, { state: { selectedMic: mic } });
    };

    const handleEditClick = (event: { stopPropagation: () => void; }, exchange: any) => {
        event.stopPropagation();
        navigate(`/EditExchange`, { state: exchange });
    };

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <Tabs
                    value={selectedOwner}
                    onChange={(event, newValue) => setSelectedOwner(newValue)}
                    aria-label="Owner Tabs"
                >
                    {uniqueOwners.map(owner => (
                        <Tab key={owner} label={owner} value={owner} />
                    ))}
                </Tabs>
                {loading ? (
                    <MoonLoader color="#282c34"/>
                ) : (
                    filteredExchanges.length === 0 ? (
                        <p>No exchanges found for selected owner.</p>
                    ) : (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>MIC</TableCell>
                                        <TableCell>Country Code</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredExchanges.map((exchange) => (
                                        <TableRow key={exchange.id}
                                                  className="clickable-row"
                                                  style={{backgroundColor: exchange.active ? '' : '#f5f5f5'}}
                                                  onClick={() => handleRowClick(exchange.mic)}>
                                            <TableCell>{exchange.name}</TableCell>
                                            <TableCell>{exchange.mic}</TableCell>
                                            <TableCell>{exchange.countryCode}</TableCell>
                                            <TableCell>
                                                <Link to={`/EditExchange`} state={exchange} className="clt-row"
                                                      style={{textDecoration: 'none', color: 'inherit'}}>
                                                    <Button variant="outlined"
                                                            onClick={(event) =>
                                                                handleEditClick(event, exchange)}>Edit</Button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                )}
            </VOCSETLayout>
        </div>
    );
}

export default ExchangeDashboard;
