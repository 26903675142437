import React, {useState, useEffect} from 'react';
import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tab,
    Tabs,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    Button
} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import {Association, Company} from "../../types/APITypes";
import CompanyService from '../../services/company.service';
import Table from '@mui/material/Table';
import {Link, useNavigate} from 'react-router-dom';
import VOCSETLayout from '../../views/VOCSETLayout';
import {dropdownItems} from '../../views/dropdownItems';
import {MoonLoader} from "react-spinners";
import ClearerAssociations from './ClearerAssocations';
import ExecAssociations from './ExecAssociations';
import AssociationService from '../../services/association.service';


createTheme();

const CompanyDashboard = () => {
    const [companies, setCompanies] = useState<Company[]>([]);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [selectedRole, setSelectedRole] = useState<string>('INTERNAL');
    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
    const [clrAssociations, setClrAssociations] = useState<Association[]>([]);
    const [execAssociations, setExecAssociations] = useState<Association[]>([]);
    const [openAssociationDialog, setOpenAssociationDialog] = useState(false);
    const [openClearerDialog, setOpenClearerDialog] = useState(false);
    const [openExecDialog, setOpenExecDialog] = useState(false);
    const [privileges, setPrivileges] = useState<string[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const storedPrivileges = localStorage.getItem('privileges');
        if (storedPrivileges) {
            setPrivileges(storedPrivileges.split(','));
        }
    }, []);

    const fetchCompanies = async () => {
        setLoading(true);
        try {
            const response = await CompanyService.getCompanies();
            // set the tab to the role of the first company
            const uniqueRoles = Array.from(new Set(response.result.flatMap((company: { roles: any[]; }) => company.roles.map(role => role.name))));
            if (uniqueRoles.length > 0) {
                // @ts-ignore
                setSelectedRole(uniqueRoles[0]);
            }
            setCompanies(response.result);
        } catch (error) {
            console.error('Error fetching companies:', error);
            setError('Error fetching companies. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCompanies()
            .then(() => console.log('Companies fetched successfully'))
            .catch(error => console.error('Error fetching companies:', error));
    }, []);

    const uniqueRoles = Array.from(new Set(companies.flatMap(company => company.roles.map(role => role.name))));
    const displayRoles = uniqueRoles.map(role => role.replace(/_/g, ' '));
    const filteredCompanies = companies.filter(company => company.roles.some(role => role.name === selectedRole));

    const handleClientNameClick = (company: Company) => {
        setSelectedCompany(company);
        setOpenAssociationDialog(true);
    };

    const handleCloseAssociationDialog = async () => {
        setOpenAssociationDialog(false);
        setSelectedCompany(null);

        try {
            setLoading(true);
            const fetchedData = await AssociationService.getAssociations();
            setClrAssociations(fetchedData.filter((asc: { associationTypeName: string }) => asc.associationTypeName === 'clearsWith'));
            setExecAssociations(fetchedData.filter((asc: { associationTypeName: string }) => asc.associationTypeName === 'executesWith'));
        } catch (error) {
            console.error('Error fetching associations:', error);
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error fetching associations. Please try again.');
        } finally {
            setLoading(false);
        }
    };


    const handleOpenClearerDialog = () => {
        setOpenAssociationDialog(false);
        setOpenClearerDialog(true);
    };

    const handleOpenExecDialog = () => {
        setOpenAssociationDialog(false);
        setOpenExecDialog(true);
    };

    const fetchAssociations = async () => {
        try {
            const fetchedData = await AssociationService.getAssociations();
            setClrAssociations(fetchedData.filter((asc: { associationTypeName: string }) => asc.associationTypeName === 'clearsWith'));
            setExecAssociations(fetchedData.filter((asc: { associationTypeName: string }) => asc.associationTypeName === 'executesWith'));
        } catch (error) {
            console.error('Error fetching associations', error);
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error fetching associations. Please try again.');
        }
    };

    useEffect(() => {
        if (privileges.includes('READ_ANY_ASSOCIATION')) {
            const fetchData = async () => {
                await fetchAssociations();
            };

            fetchData().catch(error => console.error('Error fetching associations:', error));
        }
    }, [privileges]);


    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <Tabs value={selectedRole} onChange={(event, newValue) => setSelectedRole(newValue)}
                      aria-label="Roles Tabs">
                    {displayRoles.map((role, index) => (
                        <Tab key={uniqueRoles[index]} label={role} value={uniqueRoles[index]}/>
                    ))}
                </Tabs>
                {loading ? (
                    <MoonLoader color="#282c34"/>
                ) : (
                    companies.length === 0 ? (
                        <p>No companies found.</p>
                    ) : (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Short Name</TableCell>
                                        <TableCell>City</TableCell>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Country Code</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredCompanies.map((company) => (
                                        <TableRow key={company.id}
                                                  style={{backgroundColor: company.active ? '' : '#f5f5f5'}}>
                                            <TableCell>
                                                {selectedRole === 'CLIENT' ? (
                                                    <Button onClick={() => handleClientNameClick(company)}
                                                            variant="text" style={{textTransform: 'none'}}>
                                                        {company.name}
                                                    </Button>
                                                ) : (
                                                    <Typography>{company.name}</Typography>
                                                )}
                                            </TableCell>
                                            <TableCell>{company.shortName}</TableCell>
                                            <TableCell>{company.city}</TableCell>
                                            <TableCell>{company.code}</TableCell>
                                            <TableCell>{company.countryCode}</TableCell>
                                            <TableCell>
                                                <Link to={`/EditCompany`} state={company} className="clt-row"
                                                      style={{textDecoration: 'none', color: 'inherit'}}>
                                                    <Button variant="outlined">Edit</Button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                )}

                <Dialog open={openAssociationDialog} onClose={handleCloseAssociationDialog}>
                    <DialogContent>
                        <Typography component="h1" variant="h5" sx={{alignSelf: 'flex-start'}}>
                            Manage Associations
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleOpenClearerDialog} variant="contained" color="primary">
                            Clearing Brokers
                        </Button>
                        <Button onClick={handleOpenExecDialog} variant="contained" color="primary">
                            Executing Brokers
                        </Button>
                        <Button onClick={handleCloseAssociationDialog} variant="contained" color="secondary" sx={{bgcolor: "red", '&:hover': {bgcolor: "red"}}}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openClearerDialog} onClose={() => setOpenClearerDialog(false)}>
                    {openClearerDialog && selectedCompany && clrAssociations.length > 0 &&
                        companies.length > 0 && (
                            <ClearerAssociations
                                selectedCompany={selectedCompany}
                                associations={clrAssociations}
                                companies={companies}
                                onClose={() => setOpenClearerDialog(false)}
                                onAssociationUpdate={fetchAssociations}
                            />
                        )}
                </Dialog>

                <Dialog open={openExecDialog} onClose={() => setOpenExecDialog(false)}>
                    {openExecDialog && selectedCompany && execAssociations.length > 0 &&
                        companies.length > 0 && (
                            <ExecAssociations
                                selectedCompany={selectedCompany}
                                associations={execAssociations}
                                companies={companies}
                                onClose={() => setOpenExecDialog(false)}
                                onAssociationUpdate={fetchAssociations}
                            />
                        )}
                </Dialog>

            </VOCSETLayout>
        </div>
    );
};

export default CompanyDashboard;
