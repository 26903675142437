import React, {useEffect, useState} from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Drawer,
    List,
    Divider,
    Menu,
    MenuItem,
    Tooltip,
    Paper
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {useNavigate} from 'react-router-dom';
import useStyles from '../useStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from "@mui/material/Box";
import {generalItems} from "./menuData";
import {AdminItems} from "./menuData";
import {styled, createTheme, ThemeProvider} from '@mui/material/styles';
import Container from "@mui/material/Container";
import UserService from '../services/user.service';

interface LayoutProps {
    children: React.ReactNode;
    dropdownItems: string[];
}

function VOCSETLayout({children, dropdownItems}: LayoutProps) {
    const [open, setOpen] = useState(true);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [privileges, setPrivileges] = useState<string[]>([]);
    const [userDetails, setUserDetails] = useState<any>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const storedPrivileges = localStorage.getItem('privileges');
        if (storedPrivileges) {
            setPrivileges(storedPrivileges.split(','));
        }

        const fetchUserDetails = async () => {
            try {
                const username = localStorage.getItem('currentUser');
                if (username) {
                    const userData = await UserService.getSingleUser(username);
                    setUserDetails(userData);
                }
            } catch (error) {
                console.error('Error fetching user details', error);
            }
        };

        fetchUserDetails();
    }, []);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const defaultTheme = createTheme();


    const handleCloseUserMenu = (option: string) => {
        setAnchorElUser(null);
        if (option === 'Log Out') {
            handleLogout();
        } else if (option === 'Company Settings') {
            navigate('/CompanyDashboard');
        } else if (option === 'Edit Profile') {
            navigate('/EditProfile');
        } else if (option === 'API Management') {
            navigate('/APIDashboard');
        }
    };

    const handleLogout = async () => {
        try {
            localStorage.clear()
            navigate('/');
        } catch (error) {
            console.error("Error logging out", error);
        }
    };

    const {
        classes,
        cx
    } = useStyles();

    const drawer = (
        <div>
            <div className={classes.toolbar}/>
            <Divider/>
            <List>{generalItems}</List>
            <Divider/>
            <List>{/* Render AdminItems directly */}
                <AdminItems/>
            </List>
        </div>
    );

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <AppBar position="absolute" className={classes.appBar}>
                    <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography variant="h6" color="inherit" noWrap>
                            VOCSET - Voice Order Confirmation System for Electronic Trading
                        </Typography>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {userDetails &&
                                userDetails.firstName &&
                                userDetails.lastName &&
                                userDetails.roles.length > 0 &&
                                userDetails.roles[0].name &&
                                userDetails.ownerName && (
                                    <Typography variant="body1" sx={{ marginRight: 2 }}>
                                        {`${userDetails.firstName} ${userDetails.lastName} (${userDetails.roles[0].name})`}
                                        <br />
                                        {userDetails.ownerName}
                                    </Typography>
                                )}
                            <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <AccountCircleIcon fontSize="large"/>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={() => handleCloseUserMenu('')}
                                >
                                    {dropdownItems.map((item) => (
                                        <MenuItem key={item} onClick={() => handleCloseUserMenu(item)}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    className={`${classes.drawerPaper} ${!open && classes.drawerPaperClose}`}
                    classes={{
                        paper: `${classes.drawerPaper} ${!open && classes.drawerPaperClose}`,
                    }}
                    open={open}
                >
                    {drawer}
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar/>
                    <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                        <Paper sx={{p: 2, display: 'flex', flexDirection: 'column', height: 850}}>
                            {children}
                        </Paper>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default VOCSETLayout;
