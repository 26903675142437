import React from 'react';
import {Divider, ListItem, ListItemIcon, ListItemText, ListSubheader} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SearchIcon from '@mui/icons-material/Search';
import {Link} from 'react-router-dom';

export const generalItems = (
    <div>
        <ListItem button component={Link} to="/TradeDashboard">
            <ListItemIcon>
                <ShowChartIcon/>
            </ListItemIcon>
            <ListItemText primary="Manage Trades"/>
        </ListItem>
    </div>
);

export const AdminItems: React.FC = () => {
    // Retrieve privileges from local storage
    const privileges = localStorage.getItem('privileges');
    const privilegesArray = privileges ? privileges.split(',') : [];

    return (
        <div>
            {(privilegesArray.includes('VIEW_COMPANY')) && (
                <>
                <ListSubheader>Company Settings</ListSubheader>
                    <ListItem button component={Link} to="/CompanyDashboard">
                        <ListItemIcon>
                            <SearchIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Browse Companies"/>
                    </ListItem>
                    {privilegesArray.includes('CREATE_ANY_COMPANY') && (
                        <ListItem button component={Link} to="/CreateCompany">
                            <ListItemIcon>
                                <AddIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Create Company"/>
                        </ListItem>
                    )}
                    <Divider/>
                </>
            )}

            {(privilegesArray.includes('VIEW_ACCOUNT')) && (
                <>
                    <ListSubheader>Account Settings</ListSubheader>
                    <ListItem button component={Link} to="/AccountDashboard">
                        <ListItemIcon>
                            <SearchIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Browse Accounts"/>
                    </ListItem>
                    {privilegesArray.includes('CREATE_ANY_ACCOUNT') && (
                        <ListItem button component={Link} to="/CreateClrAccount">
                            <ListItemIcon>
                                <AddIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Create Clr Account"/>
                        </ListItem>
                    )}
                    {privilegesArray.includes('CREATE_ANY_ACCOUNT') && (
                        <ListItem button component={Link} to="/CreateExecAccount">
                            <ListItemIcon>
                                <AddIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Create Exec Account"/>
                        </ListItem>
                    )}
                    <Divider/>
                </>
            )}

            {(privilegesArray.includes('VIEW_ASSOCIATION')) && (
                <>
                    <ListSubheader>Association Settings</ListSubheader>
                    <ListItem button component={Link} to="/AssociationDashboard">
                        <ListItemIcon>
                            <SearchIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Browse Associations"/>
                    </ListItem>
                    <Divider/>
                </>
            )}

            {(privilegesArray.includes('VIEW_USER')) && (
                <>
                    <ListSubheader>User Settings</ListSubheader>
                    <ListItem button component={Link} to="/UserDashboard">
                        <ListItemIcon>
                            <SearchIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Browse Users"/>
                    </ListItem>
                    {(privilegesArray.includes('CREATE_ANY_USER') || privilegesArray.includes('CREATE_OWN_USER')) && (
                        <ListItem button component={Link} to="/CreateUser">
                            <ListItemIcon>
                                <AddIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Create User"/>
                        </ListItem>
                    )}
                    <Divider/>
                </>
            )}

            {(privilegesArray.includes('VIEW_EXCHANGE')) && (
                <>
                    <ListSubheader>Exchange Settings</ListSubheader>
                    <ListItem button component={Link} to="/ExchangeDashboard">
                        <ListItemIcon>
                            <SearchIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Browse Exchanges"/>
                    </ListItem>
                    {privilegesArray.includes('CREATE_ANY_EXCHANGE') && (
                        <ListItem button component={Link} to="/CreateExchange">
                            <ListItemIcon>
                                <AddIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Create Exchange"/>
                        </ListItem>
                    )}
                    <Divider/>
                </>
            )}

            {(privilegesArray.includes('VIEW_PRODUCT')) && (
                <>
                    <ListSubheader>Product Settings</ListSubheader>
                        <ListItem button component={Link} to="/ProductDashboard">
                            <ListItemIcon>
                                <SearchIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Browse Products"/>
                        </ListItem>
                    <Divider/>
                </>
            )}

            {(privilegesArray.includes('VIEW_ALIAS')) && (
                <>
                    <ListSubheader>Exchange Settings</ListSubheader>
                    <ListItem button component={Link} to="/AliasDashboard">
                        <ListItemIcon>
                            <SearchIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Browse Aliases"/>
                    </ListItem>
                    {privilegesArray.includes('CREATE_ANY_ALIAS') && (
                        <ListItem button component={Link} to="/CreateAlias">
                            <ListItemIcon>
                                <AddIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Create Alias"/>
                        </ListItem>
                    )}
                    <Divider/>
                </>
            )}

        </div>
    );
};
